import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./FormStyles.css";
import { useUserAuth } from "../../UserAuthContext";
import HeaderComponent from "../header/HeaderComponent";

function FormComponent() {
  const [isTopOfPage, setIsTopOfPage] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  // const navbarBackground = isTopOfPage
  //   ? "bg-black"
  //   : "bg-black/25 drop-shadow rounded-lg";
  const { user } = useUserAuth();
  // const { logOut } = useUserAuth();
  const [data, setData] = useState({
    email: "",
    title: "",
    body_html: "",
    vendor: "",
    product_type: "",
    image_src: "",
    price: "",
    cost: "",
    rip: "",
    final_cost: "",
    margin: "",
    upc: "",
    sku: "",
    shopify_added: false,
  });

  function handleChange(event) {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  }

  function finalCost(event) {
    const newCost = data.rip
      ? (parseFloat(data.cost) - parseFloat(data.rip)).toFixed(2)
      : parseFloat(data.cost);
    return newCost;
  }

  function netMargin(event) {
    const newMargin =
      (parseFloat(data.price) -
        parseFloat(data.cost) +
        (parseFloat(data.rip) || 0)) /
      parseFloat(data.price);
    return newMargin;
  }

  useEffect(() => {
    const { price, cost, rip } = data;
    if (price && cost) {
      const newMargin = netMargin(price, cost, rip);
      const newCost = finalCost(cost, rip);
      setData((prevData) => ({ ...prevData, margin: newMargin }));
      setData((prevData) => ({ ...prevData, final_cost: newCost }));
    }
  }, [data.price, data.cost, data.rip]);

  const [newSku, setNewSku] = useState();

  const getSku = async () => {
    try {
      const response = await axios.get(
        "https://salesrep-backend.herokuapp.com/api/sku"
        // "http://localhost:3010/api/sku"
      );
      const oldSku = parseInt(response["data"][0]["sku"]);
      setNewSku((oldSku + 1).toString());
    } catch (error) {
      toast.error("Could Not Fetch SKU");
    }
  };

  getSku();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://salesrep-backend.herokuapp.com/api/data",
        data
      );
      toast.success("Product Submitted Successfully!");
      // Clear form data after successful submission
      setData({
        email: "",
        title: "",
        body_html: "",
        vendor: "",
        product_type: "",
        image_src: "",
        price: "",
        cost: "",
        rip: "",
        final_cost: "",
        margin: "",
        upc: "",
        sku: "",
        shopify_added: false,
      });
    } catch (error) {
      toast.error("Whoops! Something went wrong");
    }
  };
  console.log(data);

  return (
    <div className="">
      <HeaderComponent />
      <h2 className="text-center mt-10 text-white">Product Submission Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Sales Rep Email
          <input type="text" name="sku" value={(data.email = user?.email)} />
        </label>
        <label>
          Product Title:
          <input
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Description:
          <textarea
            type="text"
            name="body_html"
            value={data.body_html}
            onChange={handleChange}
          />
        </label>
        <label>
          Brand:
          <input
            type="text"
            name="vendor"
            value={data.vendor}
            onChange={handleChange}
          />
        </label>
        <label>
          Product Type:
          <select
            id="cars"
            name="product_type"
            value={data.product_type}
            onChange={handleChange}
          >
            <option value="Brandy">Brandy</option>
            <option value="Bourbon">Bourbon</option>
            <option value="Cognac">Cognac</option>
            <option value="Gin">Gin</option>
            <option value="Liqueurs">Liqueurs</option>
            <option value="Mezcal">Mezcal</option>
            <option value="Scotch">Scotch</option>
            <option value="Wine">Wine</option>
            <option value="Whiskey">Whiskey</option>
            <option value="Tequila">Tequila</option>
            <option value="Vodka">Vodka</option>
          </select>
          {/* <input
            type="text"
            name="product_type"
            value={data.product_type}
            onChange={handleChange}
          /> */}
        </label>
        <label>
          Price:
          <input
            type="number"
            name="price"
            value={data.price}
            onChange={handleChange}
          />
        </label>
        <label>
          Cost Per Bottle:
          <input
            type="number"
            name="cost"
            value={data.cost}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          (If Any) RIP Per Bottle:
          <input
            type="number"
            name="rip"
            value={data.rip}
            onChange={handleChange}
          />
        </label>
        <label>
          Net Cost Per Bottle:
          <input type="text" name="final_cost" value={finalCost()} />
        </label>
        {data.cost && data.price ? (
          <span
            name="margin"
            value={data.margin}
            onChange={handleChange}
            className=" bg-red-200 p-2 rounded-sm"
          >
            Margin: {(netMargin() * 100).toFixed(2)}%
          </span>
        ) : (
          ""
        )}
        <label className="mt-3">
          Image Link:
          <input
            type="text"
            name="image_src"
            value={data.image_src}
            onChange={handleChange}
          />
        </label>
        <label>
          Barcode / UPC:
          <input
            type="number"
            name="upc"
            value={data.upc}
            onChange={handleChange}
          />
        </label>
        <label>
          SKU
          <input type="text" name="sku" value={(data.sku = newSku)} />
        </label>
        <button type="submit">Submit</button>
        <ToastContainer />
      </form>
    </div>
  );
}

export default FormComponent;
